<template>
	<div class="flex w-full layout--middle">
		<div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
			<vx-card class="shadow-none">
				<div class="vx-row pt-10 pb-10">
					<div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center bg-white bg-dark">
						<div class="vx-card__title mb-8">
							<h4 class="mb-4">{{ $t("Récupérez votre mot de passe") }}</h4>

							<p v-if="!boolSend" class="text-justify">
								{{
									$t(
										"Entrez l'adresse mail du compte dont vous voulez récupérer le mot de passe, nous vous enverrons un email contenant un lien pour procéder à une réinitialisation."
									)
								}}
							</p>
						</div>
						<vs-input
							v-if="!boolSend"
							v-model="email"
							v-validate="'required|email'"
							data-vv-validate-on="blur"
							name="email"
							icon="icon icon-mail"
							icon-pack="feather"
							:label-placeholder="$t('Email')"
							class="w-full no-icon-border my-3"
							color="var(--black)"
							@keypress.enter="valider"
						/>
						<span v-show="errors.has('email')" class="text-danger text-sm">{{
							errors.first("email")
						}}</span>

						<vs-alert v-if="boolSend" color="success">
							{{ $t("Un mail vous a été envoyé pour récupérer votre nouveau mot de passe !") }}
						</vs-alert>

						<vs-alert :active="msgErr === 422" color="warning">
							{{ $t("Cette adresse e-mail n'est liée à aucun compte.") }}
						</vs-alert>

						<div class="mt-3 vx-coll">
							<s-button
								v-if="!boolSend"
								ref="submit"
								class="px-4 w-full mb-3 vs-con-loading__container"
								:disabled="disabled"
								@click="valider"
							>
								{{ $t("Envoyer") }}
							</s-button>
							<s-button class="px-4 w-full" type="border" :to="{ name: 'Login' }">
								{{ $t("Retour vers la connexion") }}
							</s-button>
						</div>
					</div>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
	import { Validator } from "vee-validate";
	import i18n from "../i18n";

	// dictionnaire des messages d'erreur inputs formulaire
	const dict = {
		custom: {
			email: {
				required: () => i18n.t("Veuillez entrer l'email"),
				email: i18n.t("L'email n'est pas valide")
			}
		}
	};

	Validator.localize("fr", dict);

	export default {
		data() {
			return {
				email: "",
				boolSend: false,
				msgErr: 0,
				disabled: false
			};
		},
		methods: {
			async valider() {
				const isFormValid = await this.$validator.validateAll();

				if (isFormValid) {
					this.disabled = true;

					this.$vs.loading({
						type: "default",
						color: "var(--white)",
						background: "var(--primary)",
						container: this.$refs.submit.$el,
						scale: 0.5
					});

					this.$store
						.dispatch("sendPassword", { email: this.email })
						.then(() => {
							this.boolSend = true;
							this.disabled = false;
							this.msgErr = 0;
							this.$vs.loading.close(this.$refs.submit.$el);
						})
						.catch(err => {
							this.disabled = false;
							this.msgErr = err?.response?.status;
							this.$vs.loading.close(this.$refs.submit.$el);
						});
				}
			}
		}
	};
</script>
